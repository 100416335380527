import React,{Component} from 'react';
import {Container,Form,Button} from 'react-bootstrap'
import axios from 'axios'
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'
const apiCall = process.env.apiUrl + process.env.apiKey;
const config ={
    headers:{
        'Content-Type': 'application/json'
    }
}
console.log(apiCall); 
const cusEvent = e =>{
    e.preventDefault
    trackCustomEvent({
        category:'Message Inquiry',
        action:'Click',
        label:'contact form trigger'
    })

}
class Contact extends Component {
    // initialize state
    state = {
        email:'',
        message:'',
        loading:false
    };
    //submit handler
    handleSubmit = event =>{
        event.preventDefault();
        const messageInquery = {
            'email': this.state.email,
            'message': this.state.message
        }
        // post request to server
        axios.post('https://jr-tile-contact-service.herokuapp.com/api/send?apiKey=EMLSDC3409vmkKMVWEpevmi23i', 
        {
            'email': this.state.email,
            'message': this.state.message
        }, {config})
        .then(res=>{
            console.log(res);
            console.log(res.data);
            window.location = "/"
        })
    };
    handleEmailChange = event =>{
        this.setState({ email: event.target.value });
    };
    handleMessageChange = event =>{
        this.setState({ message: event.target.value });
    };

    render(){
        return(
            <Container>
                <p></p>
                <p></p>
                <h2><strong>Contact Us</strong></h2>
                <p>Fill the form for a free consultation and price estimate.</p>
                <Form onSubmit = { this.handleSubmit }>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control required type="email" name="email" placeholder="name@example.com" onChange= {this.handleEmailChange}/>
                   
                </Form.Group>

                
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" required name="message" rows="3" onChange= {this.handleMessageChange}/>
                    
                </Form.Group>
                    <Button type = "submit" onClick={cusEvent}> Contact Us! </Button>

             </Form>
          </Container>

        );
    }

}
export default Contact;