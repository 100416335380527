import React from 'react';
import {Container,Card,CardDeck} from 'react-bootstrap'

const serviceStyling = {
    paddingBottom:'30px'
}

const Service = () => (
    <Container style={serviceStyling}>
        <h2><strong>Our Services</strong></h2>
        <p></p>
        <CardDeck>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109316/JRTileSolutions/IMG_8810_jmlvz8.jpg" />
                <Card.Body>
                <Card.Title>Porcelain</Card.Title>

                </Card.Body>
               
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109313/JRTileSolutions/IMG_8800_ovijny.jpg" />
                <Card.Body>
                <Card.Title>Ceramic</Card.Title>
                </Card.Body>
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8789_q0mevg.jpg" />
                <Card.Body>
                <Card.Title>Marble</Card.Title>
                </Card.Body>
               
            </Card>
        </CardDeck>
        <p></p>
        <CardDeck>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109315/JRTileSolutions/IMG_8812_i6rv9x.jpg" />
                <Card.Body>
                <Card.Title>Granite</Card.Title>
              
                </Card.Body>
               
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109314/JRTileSolutions/IMG_8805_jlajud.jpg" />
                <Card.Body>
                <Card.Title>Stone</Card.Title>
                </Card.Body>
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8791_hoxk93.jpg" />
                <Card.Body>
                <Card.Title>Laminate</Card.Title>
        
                </Card.Body>
               
            </Card>
        </CardDeck>
        <p></p>

    </Container>
)
export default Service;