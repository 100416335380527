import React from "react"
import {Container , Row, Col} from "react-bootstrap"
const backStyle = {
    background:`linear-gradient(rgba(192,192,192,0.7),transparent)`,
    backgroundColor:'light-grey' /*this your primary color*/
}
const imageStyling = {
    width:'100%'
}

const aboutStyling ={
    marginTop:'5%'
}
const About = () =>(
    <Container className='light' fluid={true} style={backStyle}>
        <Container>
            <br></br>
            <h2 style={aboutStyling}><strong>About Us</strong></h2>
            <p></p>
            <Row>
                <Col lg={true} >
                    <p>With 8 years of experience of handling flooring projects with tile, we at J&R Tile Solutions provide quality creative flooring designs and installations. 
                        Your floor is left in good hands, with our installation the tile is set to stand the test of time.</p>
                
                    <p>J&R Tile Solutions has served commercial and residential flooring needs in North Carolina,
                        South Carolina, and Virginia. We are committed to our customers and their satisfaction.  Our priority is to meet or exceed our customer’s expectations. Please contact us for a free consultation  and price estimates.</p>
                    <br></br>
                </Col>
                <Col lg={true} >
                    <img style={imageStyling} src='https://res.cloudinary.com/dvhnwdkun/image/upload/v1594963279/JRTileSolutions/jrpost_bhfyhz.png'></img>
                </Col>
            </Row>
            
            <br></br>
        </Container>
    </Container>

)
export default About;