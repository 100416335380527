import React from "react"
import {Jumbotron, Container, Button} from 'react-bootstrap';
//import {useStaticQuery, graphql} from 'gatsby'

const HeadlineJumbotron = () => {
  const style={  
    backgroundImage: "url(" + "https://res.cloudinary.com/dvhnwdkun/image/upload/v1597524406/JRTileSolutions/tilehead_jnduz8.png" + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maxWidth:'100%'
  }
const textstyle ={
    minHeight: '100%',
   maxWidth: 'fit-content',
    backgroundColor: 'rgb(139, 168, 173,.5)',
    opacity: '1',
    padding: '50px 50px 130px 40px',
    marginLeft: '0%',
    marginBottom: '0',
    marginTop: '0'

}
const lmao = {
  opacity: '1',
}
  const jmbButton ={
    marginTop:'100px',
    marginLeft:'5px',
    marginBottom:'0px'
    
  }
    return(
      <Jumbotron fluid style={style}>
      <Container> 
   

        <div style={textstyle}> 
          <div style ={lmao}>
            <h1>J&R</h1>
            <h1>Tile</h1>
            <h1>Solutions</h1>
            <h6>North Carolina | South Carolina | Virgina</h6>
            <Button variant="info" href="https://www.facebook.com/JR-Tile-Solutions-103769461373636" target="_blank" style={jmbButton}>Facebook</Button>{' '}
            </div>
        </div>
      </Container>
    </Jumbotron>
    
  )
}

export default HeadlineJumbotron;