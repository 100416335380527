import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadlineJumbotron from "../components/index/jumbotron"
import Service from "../components/index/services"
import Contact from "../components/index/contact"
import About from "../components/index/about"
//import ImageGallery from "../components/cloudinary/imageGallery"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    < HeadlineJumbotron />
    < Service />
    < About />
    < Contact />
   
    
  </Layout>
)

export default IndexPage
